import { useSchedule } from "routes/schedule/lib/useSchedule"
import { Link, Typography } from "@mui/material"

export function CurrentVersion({ _id, version, onClick }) {
    const { schedule } = useSchedule(_id)
    return (
        !!schedule.version &&
        (version !== schedule.version ? (
            onClick ? (
                <Link onClick={() => onClick(schedule.version)}>
                    <Typography variant="caption">(Current v{schedule.version})</Typography>
                </Link>
            ) : (
                <Typography variant="caption">(Current v{schedule.version})</Typography>
            )
        ) : (
            ""
        ))
    )
}
