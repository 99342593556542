import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Repeat } from "lib/@components/repeat"
import { SORT_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { ColorCount } from "routes/plan/plugins/review-differences/color-count"
import { PageSize } from "lib/@components/pagination/page-size"
import { SortOn } from "routes/asset-register/plugins/regime-display/sort-on"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { NoResults } from "lib/@components/no-results/no-results"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { CompareTaskItem } from "routes/plan/plugins/review-differences/compare-task-item"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { formatTime } from "lib/format-time"
import { PaginationPortal } from "lib/@components/pagination-portal"

export function CompareChanges({ from, to, type, prefix }) {
    const { total } = useBoundContext()
    const [take, setTake] = useBoundValue("take", 10)
    const [, setSkip] = useBoundValue(`changedSkip`, 0)

    const theme = useTheme()

    const changeInMinutes = to.minutes - from.minutes
    return (
        <Bound prefix={prefix}>
            <Divider sx={{ py: 1, mb: 1 }} />
            <Box pb={2}>
                <ListItemBox spacing={2} data-cy="compare-changes-summary">
                    <Typography component="div" variant="subtitle1">
                        Changed Tasks
                    </Typography>
                    <Typography component="div" variant="subtitle1" color="text.secondary">
                        ({to.tasks.total} - {((to.tasks.total / total) * 100).toFixed(0)}%)
                    </Typography>
                    <Typography component="div" variant="subtitle1" color="text.secondary">
                        {changeInMinutes > 0 ? "+" : ""}
                        {formatTime(changeInMinutes)}, now {formatTime(to.minutes)} per annum
                    </Typography>

                    <Box flex={1} />
                    <Typography variant="caption">To</Typography>
                    <Repeat
                        list={to.summarise.summary.sortBy((c) => SORT_FROM_CLASSIFICATION[c.value])}
                        item={<ColorCount />}
                    />
                    <Typography variant="caption" sx={{ pl: 2 }}>
                        From
                    </Typography>
                    <Repeat
                        list={from.summarise.summary.sortBy((c) => SORT_FROM_CLASSIFICATION[c.value])}
                        item={<ColorCount />}
                    />
                </ListItemBox>
                <ListItemBox spacing={1} mt={2} data-cy={`changes-review-pagination-${type}-table`}>
                    <Box flex={1} />
                    <PageSize setTake={setTake} take={take} />
                    <PaginationPortal />
                </ListItemBox>
                <Box
                    width={1}
                    sx={{
                        width: 1,
                        flex: 1,
                        maxHeight: "55vh",
                        overflow: "scroll",
                        "& .MuiTableCell-root": {
                            fontSize: 13,
                            whiteSpace: "nowrap",
                            py: 0.1,
                            borderRadius: "0 !important",
                        },
                        "& .MuiTableRow-root": {
                            "&": {
                                borderBottom: "1px solid " + theme.palette.action.hover,
                            },
                            "&:last-of-type": {
                                borderBottom: "none",
                            },
                        },
                    }}
                >
                    <Table size="small" sx={{ position: "relative" }} data-cy="changed-tasks-table">
                        <TableHead>
                            <TableRow>
                                <TableCell data-cy={`header-changes-${type}`}>Changes</TableCell>
                                <TableCell
                                    sx={{ position: "sticky", left: 0, zIndex: 10 }}
                                    data-cy={`header-task-${type}`}
                                >
                                    <SortOn field="title"> Task</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-schedule-${type}`}>
                                    <SortOn field="scheduleTitle">Schedule</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-version-${type}`}>Version</TableCell>
                                <TableCell data-cy={`header-where-${type}`}>
                                    <SortOn field="where">Where</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-asset-${type}`}>Asset</TableCell>
                                <TableCell data-cy={`header-frequency-${type}`}>
                                    <SortOn field="frequency.label">Frequency</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-duration-${type}`}>
                                    <SortOn field="minutes">Duration</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-annual-duration-${type}`}>
                                    <SortOn field="annualMinutes">Annual Duration</SortOn>
                                </TableCell>
                                <TableCell data-cy={`header-duration-change-${type}`}>
                                    <SortOn field="changeInMinutes">Duration Change</SortOn>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <RequestPageRepeat
                            showTop
                            pageSize={take}
                            showBottom={false}
                            fallback={
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <NoResults />
                                    </TableCell>
                                </TableRow>
                            }
                            setSkip={setSkip}
                            Component={TableBody}
                            list={to.tasks.items}
                            item={<CompareTaskItem from={from.tasks} />}
                            total={to.tasks.total}
                        />
                    </Table>
                </Box>
            </Box>
        </Bound>
    )
}
