import { Box, Chip, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { adjustLabels } from "routes/asset-register/plugins/regime-display/adjust-labels"
import { formatTime } from "lib/format-time"

export function TailoredValue({ item: key, original, tailored }) {
    let value = `${adjustLabels(key)}`
    let color = "info"

    switch (key) {
        case "suppressZeroU":
            value = (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Iconify icon="mdi:warning" />
                    <Typography variant="caption">Confirmed 0U</Typography>
                </Stack>
            )
            color = "error"
            break

        case "minutes":
            if (tailored.suppressZeroU) {
                value = null
            } else {
                if (!original.minutes) color = tailored.minutes ? "success" : "warning"
                value = (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption">Duration</Typography>
                        <strong data-cy="tailored-value-new-duration">{formatTime(tailored.minutes)}</strong>
                        <Iconify icon="mdi:arrow-left" size={20} />
                        <Typography variant="body2" color="#333a" data-cy="tailored-value-original-duration">
                            {formatTime(original.minutes)}
                        </Typography>
                    </Stack>
                )
            }
            break
        case "frequency":
            if (tailored.suppressZeroU) {
                value = null
            } else {
                if (original.frequency.label === "0U") color = tailored.frequency.label === "0U" ? "warning" : "success"
                value = (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption">Frequency</Typography>
                        <strong>every {tailored.frequency.label}</strong> <Iconify icon="mdi:arrow-left" size={20} />
                        <Typography variant="body2" color="#333a">
                            {original.frequency.label}
                        </Typography>
                    </Stack>
                )
            }
            break
        case "skill":
            value = (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="caption">Skill</Typography>
                    <Typography variant="body2" color="#333" sx={{ fontWeight: "bold" }}>
                        {tailored.skill.Skilling ?? tailored.skill}
                    </Typography>{" "}
                    <Iconify icon="mdi:arrow-left" size={20} />
                    <Typography variant="body2" color="#333a">
                        {original.skill.Skilling ?? original.skill}
                    </Typography>
                </Stack>
            )
            break
        case "classification":
            value = (
                <Stack direction="row" spacing={1} alignItems="center" data-cy="tailored-value-criticality-chip">
                    {/* <Typography variant="caption">Criticality</Typography> */}
                    <Iconify
                        sx={{ color: COLOR_FROM_CLASSIFICATION[tailored.classification] }}
                        icon={ICON_FROM_CLASSIFICATION[tailored.classification]}
                    />{" "}
                    <Typography variant="body2" color="#333" sx={{ fontWeight: "bold" }}>
                        {tailored.classification}
                    </Typography>{" "}
                    <Iconify icon="mdi:arrow-left" size={20} />
                    <Iconify
                        sx={{ color: COLOR_FROM_CLASSIFICATION[original.classification] }}
                        icon={ICON_FROM_CLASSIFICATION[original.classification]}
                    />{" "}
                    <Typography variant="body2" color="#333a">
                        {original.classification}
                    </Typography>
                </Stack>
            )
            break
        case "included":
            value = (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Iconify icon="mdi:warning" />
                    <Typography variant="caption">Excluded</Typography>
                </Stack>
            )
            color = "error"
            break
    }
    return (
        !!value && (
            <Box sx={{ py: 0.1 }}>
                <Chip
                    size="small"
                    sx={{ textTransform: "capitalize", bgcolor: color ? `${color}.lighter` : "", color: "#333" }}
                    color={color}
                    label={value}
                    data-cy="tailored-value-chip"
                />
            </Box>
        )
    )
}
