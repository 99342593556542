import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { appRoute } from "routes/app/home.runtime"

appRoute.register(
    "system-info",
    <Lazy
        importer={() => import("routes/sfg20-admin/plugins/system-info/system-info")}
        extract="SystemInfo"
        demands={"admin-system-info"}
    />
)
registerConditionalPageNavigation(
    () => hasDemand("systemInfo"),
    "/app/system-info",
    "System Info",
    "ic:twotone-query-stats",
    {
        group: "More",
        priority: 500,
    }
)
