/**
 * Create a simplified task id that removes the period and asset
 * @param {string} taskId - the id to simplify
 * @returns {string} the simplified id
 */
export function simplifyTaskId(taskId) {
    const [path, code] = taskId.split("!")
    const codes = code.split(".")
    switch (codes.length) {
        case 2:
            // This is a new id without details
            return `${path}!${codes.join(".")}`

        case 3:
            // This is an old id without details
            return `${path}!${codes.join(".")}`

        case 4:
            // This is a new id with details

            return `${path}!${codes.slice(0, 2).join(".")}`

        default:
            // Old id with details
            return `${path}!${codes.slice(0, 3).join(".")}`
    }
}
