import { Box, Link, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { formatTime } from "lib/format-time"
import { CurrentVersion } from "routes/plan/plugins/review-differences/current-version"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { asset } from "routes/asset-register/controller/asset"
import { splitTaskId } from "library/three-words/split-task-id.mjs"
import { uniqueId } from "library/database/split-id"

const fakeAsset = Symbol("fakeAsset")

export function TaskItem({ item }) {
    const { planId, type, prefix = "" } = useBoundContext()
    const assetObject = asset.useResults(item.assetId) ?? { tag: item.assetId, [fakeAsset]: true }
    const content = (
        <>
            {assetObject.description ?? assetObject.tag} {assetObject.description && <>({assetObject.tag})</>}
        </>
    )

    return (
        <TableRow data-cy={`${type}-task-item-entry-row`}>
            <TableCell sx={{ position: "sticky", left: 0, bgcolor: "background.paper" }}>
                <Link onClick={go} data-cy={`${type}-task-link-cell-content`}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify
                            icon={ICON_FROM_CLASSIFICATION[item.classification]}
                            sx={{ color: COLOR_FROM_CLASSIFICATION[item.classification] }}
                        />
                        <Tooltip title={item.title} arrow>
                            <Box
                                sx={{
                                    maxWidth: "24vw",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {item.title}
                            </Box>
                        </Tooltip>
                    </Stack>
                </Link>
            </TableCell>
            <TableCell data-cy={`${type}-task-schedule-cell-content`}>
                {item.scheduleTitle}{" "}
                <Typography variant="caption" color="text.secondary">
                    (#{item.code})
                </Typography>
            </TableCell>
            <TableCell data-cy={`${type}-task-version-cell-content`}>
                v{item.schedule.version}{" "}
                <CurrentVersion _id={item.schedule._id} version={item.schedule.version} onClick={goToSFG20} />
            </TableCell>
            <TableCell data-cy={`${type}-task-where-cell-content`}>{item.where}</TableCell>
            <TableCell data-cy={`${type}-task-asset-cell-content`}>
                {assetObject[fakeAsset] ? content : <Link onClick={goAsset}>{content}</Link>}
            </TableCell>
            <TableCell data-cy={`${type}-task-frequency-cell-content`}>{item.frequency.label}</TableCell>
            <TableCell data-cy={`${type}-task-duration-cell-content`}>{formatTime(item.minutes)}</TableCell>
            <TableCell data-cy={`${type}-task-skill-cell-content`}>{item.skill}</TableCell>
            <TableCell data-cy={`${type}-task-criticality-cell-content`}>{item.classification}</TableCell>
            <TableCell data-cy={`${type}-task-annual-minutes-cell-content`} align="right">
                {formatTime(item.annualMinutes)}
            </TableCell>
        </TableRow>
    )

    function go() {
        const { group, scheduleId, simpleTaskId } = splitTaskId(item.taskId)
        navigate(
            `/app/schedules/${group}~${prefix}${planId}!${scheduleId}?version=${item.schedule.version}&task=${simpleTaskId}`
        )
    }

    function goToSFG20(version) {
        console.log({ item })
        const { group, scheduleId, simpleTaskId } = splitTaskId(item.taskId)
        navigate(`/app/schedules/${group}~${prefix}${planId}!${scheduleId}?version=${version}&task=${simpleTaskId}`)
    }

    function goAsset() {
        navigate(`/app/asset/${uniqueId(item.assetId)}`)
    }
}
