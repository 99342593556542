import { useState } from "react"
import { getSkillsRelatedTrees } from "../controller/get-skills-related-trees"
import { TreeListComponent } from "lib/@components/tree-list/tree-list-component"
import { SkillGroupInstance } from "./skill-group-instance"
import { Bound } from "lib/@components/binding/Bound"

export function SkillTreeList({ skill, client }) {
    const [skip, setSkip] = useState(0)
    const related = getSkillsRelatedTrees.useResults({
        id: skill,
        client,
        skip,
        take: 10,
    })
    return (
        <Bound target={related}>
            <TreeListComponent
                title={"Tailored schedules in use"}
                items={related}
                setSkip={setSkip}
                groupComponent={<SkillGroupInstance />}
            />
        </Bound>
    )
}
