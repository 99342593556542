import { Box, Link, Stack, TableCell, TableRow, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { createComparableTask } from "routes/asset-register/plugins/regime-display/server/create-comparable-task.mjs"
import { Repeat } from "lib/@components/repeat"
import { TailoredValue } from "routes/asset-register/plugins/general-tailoring/tailored-value"
import { CurrentVersion } from "routes/plan/plugins/review-differences/current-version"
import { asset } from "routes/asset-register/controller/asset"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { splitTaskId } from "library/three-words/split-task-id.mjs"
import { formatTime } from "lib/format-time"
import { uniqueId } from "library/database/split-id"

const fakeAsset = Symbol("fakeAsset")

export function CompareTaskItem({ item, index, from }) {
    const former = createComparableTask(from.items[index])
    const current = createComparableTask(item)

    for (const [key, value] of Object.entries(current)) {
        if (former[key] === value) {
            delete current[key]
        }
    }
    const { planId, prefix = "" } = useBoundContext()
    const assetObject = asset.useResults(item.assetId) ?? { tag: item.assetId, [fakeAsset]: true }
    const content = (
        <>
            {assetObject.description ?? assetObject.tag} {assetObject.description && <>({assetObject.tag})</>}
        </>
    )
    return (
        <TableRow data-cy="changed-task-item-entry-row">
            <TableCell data-cy="changed-task-changes-cell-content">
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        "& .MuiChip-sizeSmall": {
                            fontSize: 11,
                        },
                        "& .MuiTypography-body2": {
                            fontSize: 11,
                        },
                    }}
                >
                    <Repeat list={Object.keys(current)} item={<Change former={from.items[index]} current={item} />} />
                </Stack>
            </TableCell>
            <TableCell
                sx={{ position: "sticky", left: 0, bgcolor: "background.paper" }}
                data-cy="changed-task-cell-content"
            >
                <Link onClick={go}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify
                            icon={ICON_FROM_CLASSIFICATION[item.classification]}
                            sx={{ color: COLOR_FROM_CLASSIFICATION[item.classification] }}
                        />
                        <Box>{item.title}</Box>
                    </Stack>
                </Link>
            </TableCell>

            <TableCell data-cy="changed-task-schedule-cell-content">
                {item.scheduleTitle}{" "}
                <Typography variant="caption" color="text.secondary">
                    (#{item.code})
                </Typography>
            </TableCell>
            <TableCell data-cy="changed-tasks-cell-content-version">
                v{item.schedule.version}{" "}
                <CurrentVersion onClick={goToSFG20} _id={item.schedule._id} version={item.schedule.version} />
            </TableCell>

            <TableCell data-cy="changed-task-where-cell-content">{item.where}</TableCell>
            <TableCell data-cy="changed-tasks-cell-content-asset">
                {assetObject[fakeAsset] ? content : <Link onClick={goAsset}>{content}</Link>}
            </TableCell>
            <TableCell data-cy="changed-task-frequency-cell-content">{item.frequency.label}</TableCell>
            <TableCell data-cy="changed-task-duration-cell-content">{formatTime(item.minutes)}</TableCell>
            <TableCell align="right" data-cy="changed-task-annual-duration-cell-content">
                {formatTime(item.annualMinutes)}
            </TableCell>
            <TableCell align="right" data-cy="changed-task-duration-change-cell-content">
                {item.changeInMinutes > 0 ? "+" : ""}
                {formatTime(item.changeInMinutes)}
            </TableCell>
        </TableRow>
    )

    function go() {
        const { group, scheduleId, simpleTaskId } = splitTaskId(item.taskId)
        navigate(
            `/app/schedules/${group}~${prefix}${planId}!${scheduleId}?version=${item.schedule.version}&task=${simpleTaskId}`
        )
    }

    function goToSFG20(version) {
        const { group, scheduleId, simpleTaskId } = splitTaskId(item.taskId)
        navigate(`/app/schedules/${group}~${prefix}${planId}!${scheduleId}?version=${version}&task=${simpleTaskId}`)
    }

    function goAsset() {
        navigate(`/app/asset/${uniqueId(item.assetId)}`)
    }
}

function Change({ former, current, item }) {
    return <TailoredValue item={item} tailored={current} original={former} />
}
