import { useReferenceState } from "lib/@hooks/use-reference"
import { Box, IconButton, Stack } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

export function SortOn({ field, children }) {
    const { sorter = "task-sort" } = useBoundContext()

    const [sort, setSort] = useReferenceState(sorter, [], "task-sort")
    const find = sort.find((s) => s.replaceAll("-", "") === field)
    const state = find ? (find.startsWith("-") ? "desc" : "asc") : undefined
    return (
        <Stack
            direction="row"
            sx={{ cursor: "pointer", width: 1 }}
            role="button"
            onClick={change}
            spacing={1}
            alignItems="center"
        >
            <Box flex={1}>{children}</Box>
            <Box lineHeight={0} sx={{ opacity: !state ? 0.2 : 1 }}>
                <IconButton size="small" sx={{ fontSize: 16 }} data-cy="sort-on-button">
                    {state === "desc" && <Iconify icon="mdi:arrow-upward" data-cy="sort-on-desc" />}
                    {state === "asc" && <Iconify icon="mdi:arrow-downward" data-cy="sort-on-asc" />}
                    {state === undefined && <Iconify icon="mdi:arrow-up-down" data-cy="sort-on-default" />}
                </IconButton>
            </Box>
        </Stack>
    )

    function change({ shiftKey }) {
        let newMode

        if (!shiftKey) {
            if (state === "asc") newMode = "-" + field
            if (state === "desc") newMode = undefined
            if (!state) newMode = field
        } else {
            if (state === "asc") newMode = undefined
            if (state === "desc") newMode = field
            if (!state) newMode = "-" + field
        }
        setSort([newMode].filter(Boolean))
    }
}
