import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"
import { ClientPanels, ClientTabs } from "slot-definitions"
import { TabPanel } from "@mui/lab"
import { Tab } from "@mui/material"
import { Advanced } from "routes/client-admin/plugins/advanced-config/advanced"

registerTab({
    tab: "clientAdmin",
    id: "advancedconfig",
    title: "Advanced",
    content: <Advanced $lazy />,
    predicate: () => hasDemand("clientAdmin"),
})

ClientTabs.plug(<Tab label="Advanced" value="Advanced" priority={1000} />)

ClientPanels.plug(
    <TabPanel value="Advanced">
        <Advanced $lazy />
    </TabPanel>
)
