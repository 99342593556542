import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { CompareChanges } from "routes/plan/plugins/review-differences/compare-changes"
import { SingleListOfChanges } from "routes/plan/plugins/review-differences/single-list-of-changes"

export function ChangesViewer({ type, label = type, prefix }) {
    const { [type]: results, [prefix]: linkIdPrefix } = useBoundContext()

    if (Array.isArray(results)) {
        return <CompareChanges prefix={linkIdPrefix} type={type} to={results[0]} from={results[1]} label={label} />
    } else {
        return <SingleListOfChanges prefix={linkIdPrefix} results={results} label={label} type={type} />
    }
}
