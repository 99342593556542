import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Stack } from "@mui/material"
import { AllowPrint } from "lib/@components/pdf/allow-print"

export function PrintableStack(props) {
    const { isPDF } = useBoundContext()
    return (
        <AllowPrint>
            <Stack {...props} sx={{ ...props.sx, display: isPDF ? "block" : undefined }} />
        </AllowPrint>
    )
}
