import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getClientProfile = api(async function getClientProfile({ client } = {}) {
    console.log({ thePrifleClientIs___: client })

    return query(
        gql`
            query GetClientProfile($client: String) {
                getClientProfile(client: $client) {
                    id
                    name
                    address
                    city
                    postcode
                    phone
                    email
                    website
                    photoURL
                    problemEmail
                }
            }
        `,
        { client },
        { returns: "getClientProfile" }
    )
})
