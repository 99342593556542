import { hasDemand } from "lib/authorization/has-demand"
import { navigate } from "lib/routes/navigate"
import { infoSnackbar } from "lib/snackbar/info-snackbar"

export function redirectTechnicalUpdates(newRoute) {
    const currentLocation = window.location.href
    if (hasDemand("$authoringAdmin-bulletinEditor") && currentLocation.includes("technical-updates")) {
        navigate(newRoute)
        infoSnackbar(
            "This page is now located in Authoring Admin for technical authors. Please update your bookmarks or saved links."
        )
    }
}
