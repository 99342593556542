import { registerTab } from "lib/@components/tabs"
import { REVIEW_TABS } from "routes/plan/review-tabs"
import { ChangesFromPublished } from "routes/plan/plugins/review-differences/changes-from-published"
import { ChangesView } from "routes/plan/plugins/review-differences/changes-view"
import { ChangesFromCurrentSfg20 } from "routes/plan/plugins/review-differences/changes-from-current-sfg20"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_REGIME_TASK_MANAGEMENT } from "routes/asset-register/plugins/regime-display/regime-task-management-feature"
import { ScheduleChanges } from "./schedule-changes"
import { ChangesViewer } from "./changes-viewer"

registerTab({
    tab: REVIEW_TABS,
    id: "review-changes",
    title: "Review Changes",
    content: (
        <ChangesFromPublished>
            <ChangesView $lazy prefix="publish|" comparedTo="live|" />
        </ChangesFromPublished>
    ),
    predicate: ({ planId }) => getTreeIndex().retrieve(`live|${planId}`),
})

registerTab({
    tab: REVIEW_TABS,
    id: "review-sfg20-changes",
    title: "Variation from Library",
    content: (
        <ChangesFromPublished>
            <ChangesFromCurrentSfg20 $lazy prefix="publish|" />
        </ChangesFromPublished>
    ),
    predicate: () => isEnabled(FEATURE_FLAG_REGIME_TASK_MANAGEMENT),
})

ScheduleChanges.plug(<ChangesViewer prefix="comparedTo" type="removed" label="Removed Tasks" priority={101} />)
ScheduleChanges.plug(<ChangesViewer prefix="prefix" type="changed" label="Changed Tasks" priority={151} />)
ScheduleChanges.plug(<ChangesViewer prefix="prefix" type="added" label="Additional Tasks" priority={201} />)
