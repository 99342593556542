import { Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"

export function ColorCount({ item }) {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center" data-cy={`${item.count}-${item.value}`}>
            <Iconify
                icon={ICON_FROM_CLASSIFICATION[item.value]}
                sx={{ color: COLOR_FROM_CLASSIFICATION[item.value] }}
            />
            <Typography variant="body2">{item.count}</Typography>
        </Stack>
    )
}
