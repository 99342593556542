import { useEffect, useState } from "react"

import { AllowPrinting } from "lib/@components/pdf/allow-printing"
import { RootPlugs } from "slot-definitions"

export function AllowPrint({ children }) {
    useEffect(() => {
        AllowPrinting.raise(true)
        return () => AllowPrinting.raise(false)
    }, [])
    return children
}

// Utility to add the allow-print class to the root element if printing should be allowed
RootPlugs.plug(<SetAllowPrint />)

function SetAllowPrint() {
    const [printingAllowed, setAllowed] = useState(0)
    useEffect(() => {
        const root = document.querySelector("#root")
        if (printingAllowed > 0) {
            root.className = "allow-print"
        } else {
            root.className = ""
        }
    }, [printingAllowed])
    AllowPrinting.useEvent((allow) => {
        setAllowed((current) => current + (allow ? +1 : -1))
    })
    return null
}
