import { simplifyTaskId } from "library/three-words/simplify-task-id.mjs"

/**
 * Splits a task id that comes from the planner into its individual parts.
 *
 * @param {string} taskId - The task id to split.
 * @returns {{
 *  group: string,
 *  scheduleId: string,
 *  taskId: string,
 *  simpleTaskId: string,
 *  scheduleTaskId: string,
 * }} - An object containing the split parts of the task id.
 */
export function splitTaskId(taskId) {
    if (!taskId) return {}
    const [groupParts, scheduleParts] = taskId.split("!")
    const [, group] = groupParts.split(".")
    const [scheduleId] = scheduleParts.split(".")
    const [, simplifiedParts] = simplifyTaskId(taskId).split("!")
    const scheduleTaskId = simplifiedParts.split(".").slice(1).join(".")
    return {
        group,
        scheduleId,
        taskId: scheduleParts.split(".").slice(1).join("."),
        simpleTaskId: simplifyTaskId(taskId),
        scheduleTaskId,
    }
}
