import { SystemInfoPanels, SystemInfoTabs } from "slot-definitions"
import { Tab } from "@mui/material"
import { TabPanel } from "@mui/lab"

export function registerSystemInfoTab(name, content, priority = 100, predicate = () => true) {
    SystemInfoTabs.plug(<Tab if={predicate} label={name} value={name} priority={priority} />)
    SystemInfoPanels.plug(
        <TabPanel if={predicate} value={name}>
            {content}
        </TabPanel>
    )
}
