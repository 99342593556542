export let defaultDemands = [
    {
        name: "accessAllRegimes",
        group: "Administrator",
        purpose: "Access all regimes",
        description:
            "Enables a user to have access to all regimes at all times, cannot be removed via the access screen.",
        icon: "ic:round-admin-panel-settings",
        feature: "regimePermissions",
    },
    {
        name: "admin",
        group: "System",
        isSpecial: true,
        purpose: "Access all the things",
        description: "This demand is visible because you are in the MAGIC_REALM",
    },
    {
        name: "admin-adoptclient",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Adopt client",
        description: "Enables administrators to become other clients",
    },
    {
        name: "admin-apitokens",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 API Token visibility",
        description: "Enables management of API tokens",
    },
    {
        name: "admin-clientmigration",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Migrate client functionality",
        description: "Enables use of the client migration to the new application",
    },
    {
        name: "admin-companies",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Companies list",
        description: "Enables visibility of the SFG20 admin companies list",
    },
    {
        name: "admin-databasetransfer",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Database migration",
        description: "Enables database migration",
    },
    {
        name: "admin-debug",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Debug Client Licenses",
        description: "Enable debugging of client license issues",
    },
    {
        name: "admin-enable-user-removal",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Enable Individual User removal",
        description: "SFG20 Enable Individual User removal",
    },
    {
        name: "admin-fubspot",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Fake Hubspot",
        description: "Enables mocking of licenses and products",
    },
    {
        name: "admin-helpcentre",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Help centre administration",
        description: "Enables administration of the SFG20 help centre content",
    },
    {
        name: "admin-system-info",
        group: "System",
        isSpecial: true,
        purpose: "FiQ System Info",
        description: "Enables visibility of the FiQ System Info area",
    },
    {
        name: "admin-users",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Users list",
        description: "Enables visibility of the SFG20 admin users list",
    },
    {
        name: "advancedTailoring",
        group: "Regimes",
        isSpecial: true,
        purpose: "Advanced tailoring",
        description: "Gives user full schedule tailoring permissions.",
    },
    {
        name: "authoringAdmin-bulletinEditor",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Create, edit or delete a technical / schedule update",
        description: "Enables a user to create, edit or delete a technical or schedule update.",
    },
    {
        name: "authoringAdmin-exportSchedule",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Export a schedule",
        description: "Enables a user to search and download a schedule.",
    },
    {
        name: "beta-tester-feature-assets",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Enable Beta test features for Asset Mapping for specific users / clients",
        description: "Enables user to see features for Asset Mapping we are beta testing",
    },
    {
        name: "canMakeShare",
        group: "Regimes",
        purpose: "Create sharing links",
        description: "Enables a user to create and manage sharing links.",
        icon: "ic:round-share",
    },
    {
        name: "clientAdmin",
        group: "Administrator",
        purpose: "Administration account",
        description:
            "Administration account - User can add users, create and edit Role permissions, update company Logo and edit custom skills.",
    },
    {
        name: "downloadPdf",
        group: "Regimes",
        isSpecial: true,
        purpose: "Enable PDF downloads",
        description: "Enable this user to download PDFs from share links.",
    },
    {
        name: "manageAccess",
        group: "Administrator",
        purpose: "Manage access settings",
        description: "Enables a user to grant access to items they themselves have access to.",
        icon: "ic:round-manage-accounts",
        feature: "regimePermissions",
    },
    {
        name: "manageRegime",
        group: "Regimes",
        purpose: "Manage maintenance regimes",
        description: "User can create and edit maintenance regimes and can tailor schedules within them.",
        icon: "ic:round-edit",
    },
    {
        name: "multipleRegimes",
        group: "Regimes",
        isSpecial: true,
        purpose: "Manage multiple regimes",
        description: "User can create and edit multiple maintenance regimes.",
    },
    {
        name: "professional-services",
        group: "Professional Services",
        isSpecial: true,
        purpose: "Enable Asset Mapping all features for professional services",
        description: "Enable Asset Mapping all features for professional services.",
    },
    {
        name: "publishRegime",
        group: "Regimes",
        purpose: "Review and publish maintenance regimes",
        description: "Enables a user to review and publish maintenance regimes.",
        icon: "ic:round-manage-search",
    },
    {
        name: "publishSchedule",
        group: "Schedules",
        purpose: "Review and publish schedules",
        description: "Enables a user to review and publish schedules.",
    },
    {
        name: "residential_sets",
        isSpecial: true,
        group: "Sets",
        purpose: "Grant access to residential sets",
        description: "User can access all schedule in the residential sets collection",
    },
    {
        name: "scheduleEditor",
        group: "Schedules",
        productDemand: true,
        purpose: "Manage custom schedules",
        description: "User can access all schedules purchased and created by themselves and others in the company.",
    },
    {
        name: "technicalAuthor",
        group: "Super Admin",
        isSpecial: true,
        purpose: "Publish and edit technical updates",
        description: "Enables a user to be a Technical Author.",
    },
]

if (process.env.ALL_DEMANDS) {
    defaultDemands.push({
        name: "staff",
        group: "SFG20 Admin",
        purpose: "SFG20 Staff Member",
        description: "This grants access to the SFG20-admin panel",
    })
}

export const defaultDemandLookup = defaultDemands.reduce((a, c) => ({ ...a, [c.name]: c }), {})
