import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"
import { ClientPanels, ClientTabs } from "slot-definitions"
import { Tab } from "@mui/material"

import { TabPanel } from "@mui/lab"
import { FiQStats } from "routes/settings/plugins/stats/components/fiq-stats"
import { registerFeature } from "lib/features"
import { Feature, isEnabled } from "lib/@components/feature"
import { Lazy } from "lib/make-lazy"

export const FEATURE_FLAG_CLIENT_STATS = registerFeature({
    numericalId: 200,
    name: "Client Statistics",
    id: "client-statistics-all-users",
    description: `Client Statistics for admin users`,
    readyForBeta: false,
    readyForProduction: false,
})

export const FEATURE_FLAG_SYSTEM_STATS = registerFeature({
    numericalId: 201,
    name: "System Statistics",
    id: "system-statistics-all-users",
    description: `System Statistics for admin users`,
    readyForBeta: false,
    readyForProduction: false,
})

ClientTabs.plug(
    <Tab if={() => isEnabled(FEATURE_FLAG_SYSTEM_STATS)} label="Stats" value="client-stats" priority={1050} />
)

ClientPanels.plug(
    <TabPanel value="client-stats" demand="admin-debug">
        <Feature feature={FEATURE_FLAG_CLIENT_STATS} fallback={<>{FEATURE_FLAG_CLIENT_STATS} is not enabled.</>}>
            <Lazy importer={() => import("./client-stats")} extract="ClientStats" label={"ClientStats..."} />
        </Feature>
    </TabPanel>
)

registerTab({
    tab: "sfg20AdminTabs",
    id: "fiq-stats",
    title: "System Statistics",
    content: <FiQStats $lazy priority={150} />,
    predicate: () => hasDemand("clientAdmin") && isEnabled(FEATURE_FLAG_SYSTEM_STATS),
})
