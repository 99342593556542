import { Box } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { AllowPrint } from "lib/@components/pdf/allow-print"

export function InPdf({ children }) {
    const { isPDF } = useBoundContext()
    return (
        !!isPDF && (
            <AllowPrint>
                <Box className="allow-print">{children}</Box>
            </AllowPrint>
        )
    )
}
