import { decamelize } from "library/decamelize"

export function adjustLabels(label) {
    label = decamelize(label, " ").toLowerCase()
    switch (label) {
        case "suppress zero u":
            return "Confirm 0U"
        case "minutes":
            return "duration"
        case "classification":
            return "criticality"
        case "skill set":
            return "skill"
        default:
            return label
    }
}
